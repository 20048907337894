







































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
// import Loader from '@/components/loader.vue'; // @ is an alias to /src

@Component({
  components: {
    Header,
    Sidebar,
    // Loader,
  },
})
export default class Error extends Vue {
  
  mounted() {  
  }


}
